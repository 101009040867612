import React from 'react';

import Scene from 'app/components/Scene';
import Container from 'app/components/Container';

import styles from './LegalScene.module.scss';

const LegalScene = ({ title, children }) => (
    <Scene>
        <div className={styles.root}>
            <Container>
                <h2 className={styles.title}>{title}</h2>
                <div className={styles.panel}>{children}</div>
            </Container>
        </div>
    </Scene>
);

export default LegalScene;
