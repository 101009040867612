import React from 'react';

import LegalScene from 'app/components/LegalScene';

const PrivacyScene = () => (
    <LegalScene title="Privacy & Cookies">
        <h3>Our Policy for Protecting Your Online Privacy</h3>
        <p>
            This website uses Google Analytics to help analyse how users use the site. The tool uses
            &ldquo;cookies&rdquo;, which are text files placed on your computer, to collect standard
            Internet log information and visitor behavior information in an anonymous form. The
            information generated by the cookie about your use of the website (including IP address)
            is transmitted to Google. This information is then used to evaluate visitors&#39; us of
            the webiste and to compile statistical reports on website activity for Wot No Mushy
            Peas.
        </p>
        <p>
            We will never (and will not allow any third party to) use the statistical analytics tool
            to track or to collect any Personally Identifiable Information (PII) of visitors to our
            site. Google will not associate your IP address with any other data held by Google.
            Neither we nor Google will link, or seek to link, an IP address with the identity of a
            computer user. We will not associate any data gathered from this site with any
            Personally Identifiable Information from any source.
        </p>
        <p>
            You may choose to accept or decline cookies. Most Web browsers automatically default to
            accept them, but you can usually modify your browser setting to decline cookies. If you
            reject cookies by changing your browser settings then be aware that this may disable
            some of the functionality on our website.
        </p>

        <h3>3rd Party Policies</h3>
        <p>
            Related services and offerings linked to or from this website have their own privacy
            statements that can be viewed by clicking on the corresponding links within each
            respective website. Since we do not have control over the policies or practices of
            participating merchants and other third parties, we are not responsible for the privacy
            practices or contents of those sites. We recommend you review their policies before you
            provide any personal information or complete any transaction with them.
        </p>
    </LegalScene>
);

export default PrivacyScene;
